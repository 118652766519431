@import "mixins/banner";
@include bsBanner("");


// scss-docs-start import-stack
// Configuration
@import "functions";
@import "variables";
@import "variables-dark";
@import "maps";
@import "mixins";
@import "utilities";

// Layout & components
@import "root";
@import "reboot";
@import "type";
@import "images";
@import "containers";
@import "grid";
@import "tables";
@import "forms";
@import "buttons";
@import "transitions";
@import "dropdown";
@import "button-group";
@import "nav";
@import "navbar";
@import "card";
@import "accordion";
@import "breadcrumb";
@import "pagination";
@import "badge";
@import "alert";
@import "progress";
@import "list-group";
@import "close";
@import "toasts";
@import "modal";
@import "tooltip";
@import "popover";
@import "carousel";
@import "spinners";
@import "offcanvas";
@import "placeholders";

// Helpers
@import "helpers";

// Utilities
@import "utilities/api";
// scss-docs-end import-stack

.custom-container {
  max-width: 720px !important;
}

.nav-link {
  font-size: $font-size-sm;

  &.active-link {
    color: $primary;
    text-decoration: underline solid;
    text-underline-offset: 16px;
    font-weight: $font-weight-semibold;
  }
}

.badge-success {
  padding: 0.5rem;
  color: rgba(3, 152, 85, 1) !important;
  background-color: rgba(236, 253, 243, 1) !important;
}

.badge-warning {
  padding: 0.5rem;
  color: rgba(181, 71, 8, 1) !important;
  background-color: rgba(255, 250, 235, 1) !important;
}

.badge-process {
  padding: 0.5rem;
  color: rgba(0, 83, 112, 1) !important;
  background-color: rgba(236, 250, 255, 1) !important;
}

.badge-cancel {
  padding: 0.5rem;
  color: #f87171 !important;
  background-color: #fef2f2 !important;
}

.badge-danger {
  padding: 0.5rem;
  color: rgba(180, 35, 24, 1) !important;
  background-color: #fef2f2 !important;
}

.badge-blues {
  padding: 0.5rem;
  color: #006386 !important;
  background-color: #ECFAFF !important;
}

.btn-oranges {
  color: white;
}

@include media-breakpoint-down(md) {
  .img-page-forbidden {
    height: calc(1440px /3);
    margin: 0 auto;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}
